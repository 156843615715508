import { Box, Link, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Image from "next/image";

export function Footer() {
  return (
    <Box mt={4}>
      <Divider />
      <Box my={4}>
        <Typography color="text.secondary" variant="body2" mb={2}>
          Mick n'est pas un cabinet d'avocats. Conformément à la réglementation
          en vigueur, Mick fournit uniquement un logiciel de suivi de formalités
          des professionnels libéraux. Il est conseillé de faire appel à un
          avocat pour toute question de nature juridique. Mick ne délivre par
          ailleurs pas de prestations d’expertise comptable au sens de la loi du
          17 janvier 1948 et du décret du 19 juillet 1948.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Mick SAS {" • "}
            <Link
              href="https://www.mick-digit.com/mentions-legales-politique-de-confidentialite"
              color="inherit"
              underline="hover"
              target="_blank"
            >
              Politique de confidentialité
            </Link>
            {" • "}
            <Link
              href="https://www.mick-digit.com/mentions-legales-politique-de-confidentialite"
              color="inherit"
              underline="hover"
              target="_blank"
            >
              Conditions générales d'utilisation
            </Link>
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Image
              src="/logo-mick-simple.webp"
              height={20}
              width={72}
              alt="Logo Mick"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
