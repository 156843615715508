import { useCallback, useEffect, useState } from "react";

const PARTNER_CODE_KEY = "partner-code";

export function useDetectPartnerCode() {
  const [partnerCode, setPartnerCode] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const partnerCodeParam = params.get(PARTNER_CODE_KEY);
    if (partnerCodeParam !== null) {
      localStorage.setItem(PARTNER_CODE_KEY, partnerCodeParam);
      setPartnerCode(partnerCodeParam);
    }
  }, [setPartnerCode]);

  useEffect(() => {
    const foundPartnerCode = localStorage.getItem(PARTNER_CODE_KEY);
    if (foundPartnerCode !== null) {
      setPartnerCode(foundPartnerCode);
    }
  }, [setPartnerCode]);

  const clearPartnerCode = useCallback(() => {
    localStorage.removeItem(PARTNER_CODE_KEY);
    setPartnerCode(null);
  }, []);

  return { partnerCode, clearPartnerCode };
}
