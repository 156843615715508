import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { deepOrange, red } from "@mui/material/colors";
import { useEffect, useState } from "react";

import { IMP_ACCOUNT_ID_PARAM } from "../config";
import { useAccount } from "../data/dataHooks";

export const ImpersonationBadge = () => {
  const [impersonationMode, setImpersonationMode] = useState(false);

  useEffect(() => {
    const impId = window.sessionStorage.getItem(IMP_ACCOUNT_ID_PARAM);
    if (impId !== null) {
      setImpersonationMode(true);
    }
  }, []);

  const { data: account, isError, isLoading } = useAccount(impersonationMode);

  if (!impersonationMode || isLoading || isError) {
    return null;
  }

  return (
    <Box
      sx={{
        color: "white",
        backgroundColor: deepOrange[400],
        py: 0.5,
        px: 1,
        mx: 1,
        display: "flex",
      }}
    >
      <SupervisedUserCircleIcon />
      <Stack spacing={0.5} direction="row" alignItems="center" ml={0.5}>
        <Typography component="span" fontSize={10}>
          Connecté en tant que
        </Typography>
        <Typography component="span" fontSize={10} fontWeight="bold">
          {account?.profile?.firstName} {account?.profile?.lastName}
        </Typography>
        <Tooltip title="Revenir au mode normal">
          <IconButton
            aria-label="delete"
            size="small"
            edge="end"
            sx={{ color: red[50] }}
            onClick={() => {
              window.sessionStorage.removeItem(IMP_ACCOUNT_ID_PARAM);
              window.location.reload();
            }}
          >
            {/* @ts-expect-error investigate */}
            <CloseRoundedIcon fontSize="0.7rem" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
};
