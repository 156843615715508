import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import { NextLinkComposed } from "../navigation/NextLinkComposed";
import { routeCockpit } from "../routes";

interface Props {
  slightlyDisabled?: boolean;
}

export function SubscriptionOffer({ slightlyDisabled }: Props) {
  return (
    <Tooltip title="Encore un peu de patience, on y est presque !">
      <Card
        variant="outlined"
        sx={{
          opacity: slightlyDisabled === true ? 1 : 0.4,
        }}
      >
        <CardContent
          sx={{
            backgroundColor: grey[50],
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <Typography variant="body1">
            {slightlyDisabled === true ? "Lancement imminent : " : ""}Forfait
            gestion micro-BNC
          </Typography>
        </CardContent>
        <Divider />
        <CardContent>
          <img src="./analyze.jpg" height={228} />
          <Typography variant="h5" sx={{ fontWeight: "bold" }} mt={2}>
            Je gère mon activité
          </Typography>
          <Typography variant="body1" mt={0.5}>
            Bénéficiez d'un accompagnement spécialement conçu pour votre statut
            : recettes, TVA, déclarations fiscales,...
          </Typography>
          <Stack direction="row" spacing={1} mt={2}>
            <Button
              variant="contained"
              sx={{ py: 1.5 }}
              to={routeCockpit()}
              disabled
              component={NextLinkComposed}
            >
              S'abonner
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Tooltip>
  );
}
