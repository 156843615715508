import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import {
  Stack,
  Typography,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
} from "@mui/material";

export function DescSetupOffer() {
  return (
    <Stack>
      <Typography variant="h3" component="h1" fontWeight={700} mb={2}>
        Pack d'installation
      </Typography>
      <Typography variant="body1">
        Activez votre pack et bénéficiez d'un service unique pour les jeunes
        avocat.e.s qui désirent faire toutes leurs démarches en un clin d'oeil.
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <AutoAwesomeIcon color={"secondary"} />
          </ListItemIcon>
          <ListItemText>
            <strong>
              Votre inscription automatique au service d'immatriculation
            </strong>{" "}
            vous permettant de recevoir votre SIRET sans effort.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <HealthAndSafetyIcon color={"secondary"} />
          </ListItemIcon>
          <ListItemText>
            <strong>La mise en place de votre protection sociale</strong>{" "}
            (mutuelle, prévoyance, retraite...) avec un expert Promis
            indispensable lorsque l'on commence son activité.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <FactCheckIcon color={"secondary"} />
          </ListItemIcon>
          <ListItemText>
            <strong>Une check-list détaillée</strong> reprenant point par point
            vos <strong>obligations administratives</strong> afin de ne rien
            oublier !
          </ListItemText>
        </ListItem>
      </List>
      <Typography variant="caption" color={"text.secondary"} lineHeight={1.5}>
        Mick. est réservé à la création d’une entreprise individuelle. Si vous
        souhaitez créer une société (personne morale) type SASU / EURL,
        contactez nous directement par email (mick@promis.fr)
      </Typography>
    </Stack>
  );
}
