// Keep pathnames in sync with file names in `pages` folder

export function routeHome() {
  return { pathname: "/" };
}

export function routeAdminHome() {
  return { pathname: "/admin/accounts" };
}

export function routeMyAccount() {
  return {
    pathname: "/my-account",
  };
}

export function routeLogin() {
  return {
    pathname: "/sign-in",
  };
}

export function routeSignUp() {
  return {
    pathname: "/sign-up",
  };
}

export function routeOnboarding() {
  return {
    pathname: "/onboarding",
  };
}

export function routeRegistrationProduct() {
  return {
    pathname: "/registration",
  };
}

export function routeRegistrationActivityStart() {
  return {
    pathname: "/registration/activity-start",
  };
}

export function routeRegistrationAcre() {
  return {
    pathname: "/registration/acre",
  };
}

export function routeRegistrationSie() {
  return {
    pathname: "/registration/sie",
  };
}

export function routeRegistrationCnbf() {
  return {
    pathname: "/registration/cnbf",
  };
}

export function routeRegistrationBar() {
  return {
    pathname: "/registration/bar",
  };
}

export function routeRegistrationSocialProtection() {
  return {
    pathname: "/registration/social-protection",
  };
}

export function routeRegistrationBankAccount() {
  return {
    pathname: "/registration/bank-account",
  };
}

export function routeRegistrationMutuelle() {
  return {
    pathname: "/registration/mutuelle-prevoyance",
  };
}

export function routeMickManagementSolution() {
  return {
    pathname: "/registration/mick-management-solution",
  };
}

export function routeTransactions() {
  return {
    pathname:
      typeof window !== "undefined" &&
      window.document.location.search.includes("demo")
        ? "/management/transactions"
        : "/launch-soon",
  };
}

export function routeCockpit() {
  return {
    pathname: "/management/cockpit",
  };
}

export function routeStatements() {
  return {
    pathname: "/management/statements",
  };
}

export function routeFactSheet() {
  return {
    pathname: "/fact-sheet",
  };
}
