import { z } from "zod";

function parseDateFromIsoString(isoString: string): Date {
  return new Date(isoString);
}

export const profileSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  workType: z.string(),
  workCity: z.string(),

  notificationsEnabled: z.boolean(),

  // Activity start
  nationality: z.string().nullable(),
  gender: z.enum(["MASCULIN", "FEMININ"]).nullable(),

  activityStartedAt: z.string().transform(parseDateFromIsoString).nullable(),
  professionalEmail: z.string().nullable(),
  vatMode: z.enum(["FRANCHISE", "REEL_SIMPLIFIE", "REEL_NORMAL"]).nullable(),

  birthDate: z.string().transform(parseDateFromIsoString).nullable(),
  birthDepartement: z.number().nullable(),
  birthCity: z.string().nullable(),
  birthCountry: z.string().nullable(),

  address: z.string().nullable(),
  city: z.string().nullable(),
  zipCode: z.string().nullable(),
  country: z.string().nullable(),

  maritalStatus: z
    .enum([
      "CELIBATAIRE",
      "DIVORCE",
      "EN_CONCUBINAGE",
      "MARIE",
      "PACSE",
      "VEUF",
    ])
    .nullable(),

  activityWithPartner: z.boolean().nullable(),

  partnerNationality: z.string().nullable(),
  partnerStatus: z.boolean().nullable(),
  partnerFirstName: z.string().nullable(),
  partnerLastName: z.string().nullable(),
  partnerBirthName: z.string().nullable(),
  partnerBirthDate: z.string().transform(parseDateFromIsoString).nullable(),
  partnerBirthDepartement: z.number().nullable(),
  partnerBirthCity: z.string().nullable(),
  partnerBirthCountry: z.string().nullable(),

  partnerAddress: z.string().nullable(),
  partnerCity: z.string().nullable(),
  partnerZipCode: z.string().nullable(),
  partnerCountry: z.string().nullable(),

  workplaceAddress: z.string().nullable(),
  workplaceZipCode: z.string().nullable(),
  workplaceCity: z.string().nullable(),

  activityTypeCreation: z.boolean().nullable(),

  previousActivityId: z.string().nullable(),
  previousActivityFirstName: z.string().nullable(),
  previousActivityLastName: z.string().nullable(),
  previousActivityBirthName: z.string().nullable(),
  previousActivityCompanyName: z.string().nullable(),

  employeesNumber: z.number().nullable(),
  apprenticesNumber: z.number().nullable(),
  planedHire: z.boolean().nullable(),

  socialSecurityNumber: z.string().nullable(),
  titreSejourNumber: z.string().nullable(),
  titreSejourCity: z.string().nullable(),
  titreSejourExpiredAt: z.string().transform(parseDateFromIsoString).nullable(),

  hasOtherActivity: z.boolean().nullable(),
  otherActivityType: z.string().nullable(),
  otherActivityCustom: z.string().nullable(),
  affiliatedBiologist: z.boolean().nullable(),
  proSailor: z.boolean().nullable(),
  precompteCotisationsArtiste: z.boolean().nullable(),

  contactAddress: z.string().nullable(),
  contactZipCode: z.string().nullable(),
  contactCity: z.string().nullable(),

  birthName: z.string().nullable(),

  previousNonEmployeeActivity: z.boolean().nullable(),
  previousNonEmployeeSiren: z.string().nullable(),
  previousNonEmployeeType: z.string().nullable(),
  previousNonEmployeeCity: z.string().nullable(),
  previousNonEmployeeDepartement: z.number().nullable(),
  previousNonEmployeeActivityEndedAt: z
    .string()
    .transform(parseDateFromIsoString)
    .nullable(),

  companyType: z.string().nullable(),

  fiscalMode: z.string().nullable(),

  partnerHealthInsuranceCovered: z.boolean().nullable(),
  partnerSocialSecurityNumber: z.string().nullable(),

  workPlaceType: z.string().nullable(),
  workplaceProName: z.string().nullable(),

  workplaceDomiciliationName: z.string().nullable(),
  workplaceDomiciliationSiren: z.string().nullable(),

  contactPhone: z.string().nullable(),

  publicSirene: z.boolean().nullable(),

  filledFormAt: z.string().transform(parseDateFromIsoString).nullable(),
  validatedFormAt: z.string().transform(parseDateFromIsoString).nullable(),
  handledFormAt: z.string().transform(parseDateFromIsoString).nullable(),
  siret: z.string().nullable(),

  // ACRE
  acreLess3Years: z.boolean().nullable(),
  acreCompleteControl: z.boolean().nullable(),
  sentAcreLetterAt: z.string().transform(parseDateFromIsoString).nullable(),
  completedAcreAt: z.string().transform(parseDateFromIsoString).nullable(),

  // SIE
  sieAccountCreatedAt: z.string().transform(parseDateFromIsoString).nullable(),
  sieAnswerReceivedAt: z.string().transform(parseDateFromIsoString).nullable(),
  sieContactName: z.string().nullable(),
  sieContactEmail: z.string().nullable(),

  // CNBF
  cnbfNumber: z.string().nullable(),
  cnbfAccountCreatedAt: z.string().transform(parseDateFromIsoString).nullable(),

  // Bar
  barAccountCreatedAt: z.string().transform(parseDateFromIsoString).nullable(),

  // Social protection
  socialProtectionUpdatedCard: z.boolean().nullable(),
  socialProtectionAmeliAccount: z.boolean().nullable(),
  socialProtectionCompletedAt: z
    .string()
    .transform(parseDateFromIsoString)
    .nullable(),

  // Bank account
  bankAccountCompletedAt: z
    .string()
    .transform(parseDateFromIsoString)
    .nullable(),

  // Mutuelle
  mutuelleCompletedAt: z.string().transform(parseDateFromIsoString).nullable(),

  // Prevoyance
  prevoyanceCompletedAt: z
    .string()
    .transform(parseDateFromIsoString)
    .nullable(),

  // Retraite
  retraiteCompletedAt: z.string().transform(parseDateFromIsoString).nullable(),

  // Epargne
  epargneCompletedAt: z.string().transform(parseDateFromIsoString).nullable(),

  // Management
  managementCompletedAt: z
    .string()
    .transform(parseDateFromIsoString)
    .nullable(),
});

export type Profile = z.infer<typeof profileSchema>;

export const initialProfileParamsSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  workType: z.string(),
  workCity: z.string(),
  contactPhone: z.string().nullable(),
});

export type InitialProfile = z.infer<typeof initialProfileParamsSchema>;

export const authProviderSchema = z.enum([
  "GOOGLE",
  "APPLE",
  "PASSWORD",
  "UNKNOWN",
]);

export const fileSchema = z.object({
  id: z.string(),
  description: z.string(),
  originalFileName: z.string(),
  createdAt: z.string().transform(parseDateFromIsoString),
  updatedAt: z.string().transform(parseDateFromIsoString).optional(),
  mimeType: z.string(),
});

export type File = z.infer<typeof fileSchema>;

export const accountSchema = z.object({
  id: z.string(),
  email: z.string(),
  authProvider: authProviderSchema,
  createdAt: z.string().transform(parseDateFromIsoString),
  productRegistrationEnabledAt: z
    .string()
    .transform(parseDateFromIsoString)
    .nullable(),
  productAccountingEnabledAt: z
    .string()
    .transform(parseDateFromIsoString)
    .nullable(),
  productRegistrationPromoCode: z.string().nullable(),
  productRegistrationNetPrice: z.number().nullable(),
  profile: profileSchema.nullable(),
  roles: z.array(z.string()),
  File: z.array(fileSchema).optional(),
});

export type Account = z.infer<typeof accountSchema>;

export const fullAccountSchema = accountSchema.and(
  z.object({
    stripeCustomerId: z.string().nullable(),
    firebaseId: z.string(),
  }),
);

export type FullAccount = z.infer<typeof fullAccountSchema>;
