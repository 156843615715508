import { createTheme } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    text: {
      primary: blueGrey[900],
      secondary: blueGrey[400],
    },
    primary: {
      // main: "#2c6a64", base mick
      // main: "#002366", promis dark blue
      // main: "#4169E1", promis blue
      main: "#7F00FF",
      light: "#E5CCFF",
      dark: "#6100C2",
    },
    secondary: {
      // main: "#e9af38", base mick
      main: "#4169E1",
      contrastText: "#ffffff",
      light: "#F4F6FC",
      dark: "#002366",
    },
    info: {
      main: blueGrey[300],
      light: blueGrey[50],
      dark: blueGrey[200],
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    caption: {
      color: blueGrey[600],
      lineHeight: 1,
    },
    h6: {
      // color: blueGrey[600],
      fontWeight: 600,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            "0 4.91475px 4.91475px rgba(31,40,55,.04),0 2.45737px 2.45737px rgba(31,40,55,.04),0 0 1.22869px rgba(31,40,55,.24)",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          // boxShadow:
          //   "0px 2px 4px -1px rgb(0 0 0 / 9%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 2%)",
          boxShadow:
            "0 4.91475px 4.91475px rgba(31,40,55,.04),0 2.45737px 2.45737px rgba(31,40,55,.04),0 0 1.22869px rgba(31,40,55,.24)",

          backdropFilter: "blur(5px)",
          backgroundColor: "rgba(255,255,255,.6)",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "30px",
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        focusHighlight: {
          backgroundColor: grey[500],
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontWeight: 500,
          color: "#7F00FF",
          borderRadius: "4px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          fontWeight: 600,
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          // borderRadius: "6px", base mick
          borderRadius: "0.6rem",
          textTransform: "initial",
          // fontWeight: 500, base mick
          fontWeight: 600,
          padding: "14px 32px",
          // padding: "8px 20px", base mick
          fontSize: "1.125rem",
          // fontSize: ".95rem",  base mick
          backgroundColor: "linear-gradient(#6100c2, #7f00ff)",
          ":hover": {
            boxShadow: "0 1px 2px rgba(16,29,52,.2)",
          },
        },
        outlined: {
          boxShadow: "0 1px 2px rgba(16,29,52,.1)",
          // borderRadius: "6px",
          borderRadius: "0.6rem",
          border: "none",
          textTransform: "initial",
          // fontWeight: 500,
          fontWeight: 600,
          padding: "14px 32px",
          // padding: "8px 20px", base mick
          // fontSize: ".95rem",
          fontSize: "1.125rem",
          color: "#7F00FF",
          backgroundColor: "#F7F1FE",
          ":hover": {
            border: "none",
            backgroundColor: "#E5CCFF",
            boxShadow: "0 1px 2px rgba(16,29,52,.2)",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: blueGrey[300],
        },
      },
    },
  },
});
