import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { ReactElement, ReactNode, useEffect } from "react";

import { MAIN_COLOR } from "../src";
import { IMP_ACCOUNT_ID_PARAM } from "../src/config";
// eslint-disable-next-line import/order
import { theme } from "../src/theme";

// Firebase app needs to be initialized early
// eslint-disable-next-line import/no-unassigned-import
import "../src/firebaseApp";
import { useDetectPartnerCode } from "../src/useDetectPartnerCode";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 6 * 60 * 60 * 1000,
    },
  },
});

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    const existingId = window.sessionStorage.getItem(IMP_ACCOUNT_ID_PARAM);
    if (existingId !== null) {
      return;
    }
    const startImpAccountId = new URLSearchParams(window.location.search).get(
      IMP_ACCOUNT_ID_PARAM,
    );
    if (startImpAccountId !== null) {
      window.sessionStorage.setItem(IMP_ACCOUNT_ID_PARAM, startImpAccountId);
      window.location.reload();
    }
  }, []);

  useDetectPartnerCode();

  return (
    <>
      <Head>
        <title>Mick | La solution pour avocats libéraux</title>
        <meta name="theme-color" content={MAIN_COLOR} />
        <link rel="shortcut icon" type="image/png" href="/favicon.png" />
      </Head>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            {getLayout(<Component {...pageProps} />)}
          </LocalizationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default dynamic(() => Promise.resolve(MyApp), { ssr: false });
