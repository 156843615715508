import AccountCircle from "@mui/icons-material/AccountCircle";
import CancelIcon from "@mui/icons-material/Cancel";
// import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Logout from "@mui/icons-material/Logout";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Settings from "@mui/icons-material/Settings";
import {
  Badge,
  Box,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  SwipeableDrawer,
  Typography,
  MenuItem,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { grey } from "@mui/material/colors";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { getAuth, signOut } from "firebase/auth";
import Image from "next/image";
import { LinkProps as NextLinkProps } from "next/link";
import { useRouter } from "next/router";
import { MouseEvent, useState } from "react";

import { ImpersonationBadge } from "./account/ImpersonationBadge";
import { RoleBadge } from "./account/RoleBadge";
// import { SUPPORT_EMAIL } from "./config";
import ContactModal from "./ContactModal";
import { useAccount } from "./data/dataHooks";
import { NextLinkComposed } from "./navigation/NextLinkComposed";
import { reportError } from "./reportError";
import {
  routeAdminHome,
  routeFactSheet,
  routeHome,
  routeMyAccount,
  routeRegistrationProduct,
} from "./routes";

interface MenuLinkProps {
  text: string;
  to: NextLinkProps["href"];
}

function MenuLink(props: MenuLinkProps) {
  const { asPath } = useRouter();

  let isActive = false;
  if (typeof props.to === "string") {
    isActive = asPath.startsWith(props.to);
  } else if (props.to.pathname != null) {
    isActive = asPath.startsWith(props.to.pathname);
  }

  return (
    // <Link component={NextLinkComposed} to={props.to}>
    //   <Typography
    //     variant="h6"
    //     color="text.secondary"
    //     component="div"
    //     sx={{
    //       fontSize: "1.15rem",
    //       transition: "color .2s ease-in-out",
    //       color: isActive ? "primary" : "text.secondary",
    //       "&:hover": { color: "primary.main" },
    //     }}
    //   >
    //     {props.text}
    //   </Typography>
    // </Link>
    <MenuItem component={NextLinkComposed} to={props.to} selected={isActive}>
      {props.text}
    </MenuItem>
  );
}

interface HeaderMenuProps {
  logoOnly: boolean;
}

export function HeaderMenu({ logoOnly = true }: HeaderMenuProps) {
  const [accountEl, setAccountEl] = useState<HTMLElement | null>(null);
  const accountOpen = Boolean(accountEl);
  const handleAccountClick = (event: MouseEvent<HTMLElement>) => {
    setAccountEl(event.currentTarget);
  };
  const handleAccountClose = () => {
    setAccountEl(null);
  };

  const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(menuEl);
  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setMenuEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuEl(null);
  };

  const [drawerVisible, setDrawerVisible] = useState(false);

  const { data: account } = useAccount();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              edge="start"
              aria-label="main menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
              color="primary"
            >
              <MenuRoundedIcon />
            </IconButton>
            <Menu
              anchorEl={menuEl}
              open={menuOpen}
              onClose={handleMenuClose}
              onClick={handleMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuLink
                text={"Parcours d'installation"}
                to={routeRegistrationProduct()}
              />
              <MenuLink text={"Mes informations"} to={routeFactSheet()} />
            </Menu>
          </Box>
          {logoOnly && <Box sx={{ flexGrow: 1 }} />}
          <Link
            component={NextLinkComposed}
            to={
              account?.roles[0] == "SUPERADMIN" ? routeAdminHome() : routeHome()
            }
            sx={{
              border: "1px solid",
              borderColor: grey[300],
              borderRadius: "0.5rem",
              padding: "7px 6px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              src="/logo-mick.webp"
              width={70}
              height={35}
              alt="logo Mick by Promis"
            />
          </Link>
          {!logoOnly && (
            <Stack
              spacing={3}
              ml={4}
              direction="row"
              display={{ xs: "none", md: "flex" }}
            >
              <MenuLink
                text="Parcours d'installation"
                to={routeRegistrationProduct()}
              />
              <MenuLink text="Mes informations" to={routeFactSheet()} />
            </Stack>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <ImpersonationBadge />
          <Link component={NextLinkComposed} to={routeAdminHome()}>
            <RoleBadge />
          </Link>
          {!logoOnly && (
            <Box>
              <ContactModal />
              <IconButton
                size="large"
                // aria-label={`Voir les ${dummyNotifications.length} notifications`}
                color="primary"
                onClick={() => setDrawerVisible(!drawerVisible)}
              >
                <Badge badgeContent={0} color="error">
                  {drawerVisible ? <CancelIcon /> : <NotificationsIcon />}
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleAccountClick}
                color="primary"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                anchorEl={accountEl}
                open={accountOpen}
                onClose={handleAccountClose}
                onClick={handleAccountClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem component={NextLinkComposed} to={routeMyAccount()}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Mon compte
                </MenuItem>
                {/* <MenuItem component="a" href={`mailto:${SUPPORT_EMAIL}`}>
                  <ListItemIcon>
                    <ContactSupportIcon fontSize="small" />
                  </ListItemIcon>
                  Support
                </MenuItem> */}
                {/*<MenuItem onClick={notActiveYet}>*/}
                {/*  <ListItemIcon>*/}
                {/*    <MenuBookIcon fontSize="small" />*/}
                {/*  </ListItemIcon>*/}
                {/*  Documentation*/}
                {/*</MenuItem>*/}
                <Divider />
                <MenuItem
                  sx={{ color: "error" }}
                  onClick={() => {
                    signOut(getAuth()).catch((e) => {
                      reportError(e);
                      console.log("Failed to sign out", e);
                    });
                  }}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Se déconnecter
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor="right"
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        onOpen={() => setDrawerVisible(true)}
        // onOpen={toggleDrawer(true)}
      >
        <Box
          sx={{
            width: 320,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            pt: 8,
          }}
          role="presentation"
          onClick={() => setDrawerVisible(false)}
        >
          <Box>
            <Typography variant="body1" color="text.secondary" m={3}>
              Vous n'avez pas encore de notifications
            </Typography>
            {/*{dummyNotifications.map((notification) => (*/}
            {/*  <Fragment key={notification.id}>*/}
            {/*    <Box my={3} mx={2}>*/}
            {/*      /!*<Box>*!/*/}
            {/*      /!*  <NotificationsIcon />*!/*/}
            {/*      /!*</Box>*!/*/}
            {/*      <Box>*/}
            {/*        <Box>*/}
            {/*          <Typography*/}
            {/*            sx={{*/}
            {/*              fontSize: 12,*/}
            {/*              letterSpacing: 1,*/}
            {/*              fontWeight: "bold",*/}
            {/*              color: "secondary.main",*/}
            {/*              textTransform: "uppercase",*/}
            {/*            }}*/}
            {/*            component="span"*/}
            {/*          >*/}
            {/*            {notification.category}*/}
            {/*          </Typography>*/}
            {/*          <Typography*/}
            {/*            sx={{*/}
            {/*              fontSize: 12,*/}
            {/*              color: "text.secondary",*/}
            {/*            }}*/}
            {/*            pl={1}*/}
            {/*            component="span"*/}
            {/*          >*/}
            {/*            {notification.date.toLocaleDateString()}*/}
            {/*          </Typography>*/}
            {/*        </Box>*/}
            {/*        <Typography component="h6" sx={{ fontWeight: 600 }}>*/}
            {/*          {notification.title}*/}
            {/*        </Typography>*/}
            {/*        <Typography variant="body2" color="text.secondary" mt={1}>*/}
            {/*          {notification.description}*/}
            {/*        </Typography>*/}
            {/*      </Box>*/}
            {/*    </Box>*/}
            {/*    <Divider />*/}
            {/*  </Fragment>*/}
            {/*))}*/}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <List>
            <ListItem button component={NextLinkComposed} to={routeMyAccount()}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Paramètres de notification" />
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>
    </>
  );
}
