import { faker } from "@faker-js/faker";
import { getAuth } from "@firebase/auth";
import axios from "axios";
import { DateTime } from "luxon";
import { z } from "zod";

import { BASE_API, IMP_ACCOUNT_ID_PARAM } from "./config";
import { fakeTransactionsBase } from "./data/fakeTransactionsBase";
import {
  NewTransaction,
  Transaction,
  UpdatedTransaction,
} from "./data/transactions";
import { TransactionFilters } from "./data/types";
import { mickFirebaseProject } from "./firebaseApp";
import {
  Account,
  accountSchema,
  File,
  fileSchema,
  FullAccount,
  fullAccountSchema,
  InitialProfile,
  Profile,
} from "./schemas";

function randomBetween(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

async function sleep() {
  await new Promise((resolve) => setTimeout(resolve, randomBetween(200, 600)));
}

export class MickApiClient {
  private static instance?: MickApiClient;
  test = "hey hey";
  private httpClient = axios.create({
    timeout: 30_000,
    baseURL: BASE_API + "/",
  });

  static get sharedInstance(): MickApiClient {
    if (this.instance === undefined) {
      this.instance = new MickApiClient();
    }
    return this.instance;
  }

  async healthCheck() {
    const response = await this.httpClient.get("health", {
      headers: await this.authHeaders(),
    });
    return response.data;
  }

  async listAccounts(): Promise<FullAccount[]> {
    const response = await this.httpClient.get("admin/accounts", {
      headers: await this.authHeaders(),
    });

    return z.array(fullAccountSchema).parse(response.data);
  }

  async getAccount(): Promise<Account> {
    const response = await this.httpClient.get("account", {
      headers: await this.authHeaders(),
    });
    return accountSchema.parse(response.data);
  }

  async deleteAccount(accountId: string) {
    await this.httpClient.delete(`admin/accounts/${accountId}`, {
      headers: await this.authHeaders(),
    });
  }

  async createProfile(params: InitialProfile): Promise<Account> {
    const response = await this.httpClient.post("account/profile", params, {
      headers: await this.authHeaders(),
    });

    return accountSchema.parse(response.data);
  }

  async updateProfile(
    params: Partial<Profile> & {
      event?: "immat_inpi_done" | "immat_cust_begin";
    },
  ): Promise<Account> {
    const response = await this.httpClient.put("account/profile", params, {
      headers: await this.authHeaders(),
    });

    return accountSchema.parse(response.data);
  }

  async updateProfileAsAdmin(
    id: string,
    params: Partial<Profile> & {
      event?: "immat_inpi_done" | "immat_cust_begin";
    },
  ): Promise<Account> {
    const response = await this.httpClient.put("account/profile", params, {
      headers: await this.authAdminHeaders(id),
    });

    return accountSchema.parse(response.data);
  }

  async registerProduct(
    registrationOk: boolean,
    promoCode: string | false,
    promoAmount: number,
    remainingAmount: number,
    promoCodeName?: string,
  ): Promise<Account> {
    const response = await this.httpClient.put(
      "account/register",
      {
        registrationOk,
        registrationType: "creation",
        promoCode,
        promoAmount,
        remainingAmount,
        promoCodeName,
      },
      {
        headers: await this.authHeaders(),
      },
    );

    return accountSchema.parse(response.data);
  }

  async getFiles(): Promise<File[]> {
    const response = await this.httpClient.get("files", {
      headers: await this.authHeaders(),
    });

    return z.array(fileSchema).parse(response.data);
  }

  async downloadFile(fileId: string): Promise<string> {
    const response = await this.httpClient.get(`files/${fileId}/download`, {
      headers: await this.authHeaders(),
    });

    return z.string().parse(response.data);
  }

  async viewFile(fileId: string): Promise<string> {
    const response = await this.httpClient.get(`files/${fileId}/view`, {
      headers: await this.authHeaders(),
    });

    return z.string().parse(response.data);
  }

  async deleteFile(fileId: string): Promise<File> {
    const response = await this.httpClient.delete(`files/${fileId}`, {
      headers: await this.authHeaders(),
    });

    return fileSchema.parse(response.data);
  }

  async uploadFile(file: File, description: string): Promise<File> {
    const formData = new FormData();
    // @ts-expect-error investigate
    formData.append("file", file, file.name);
    formData.append("description", description);
    const response = await this.httpClient.post("files", formData, {
      headers: {
        ...(await this.authHeaders()),
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }

  async getCheckoutUrl(promotionCodeId?: string): Promise<{ url: string }> {
    const response = await this.httpClient.post(
      "payments/checkout",
      { promotionCodeId },
      {
        headers: await this.authHeaders(),
      },
    );

    return z.object({ url: z.string() }).parse(response.data);
  }

  async checkPromotionCode(
    code: string,
  ): Promise<{ promotionCodeId: string | null; amountOff: number | null }> {
    const response = await this.httpClient.get(
      `payments/check-promotion-code/${code}`,
      {
        headers: await this.authHeaders(),
      },
    );

    return z
      .object({
        promotionCodeId: z.string().nullable(),
        amountOff: z.number().nullable(),
      })
      .parse(response.data);
  }

  async synchronizeProductsData(): Promise<unknown> {
    const response = await this.httpClient.post(
      "payments/synchronize-products-data",
      null,
      { headers: await this.authHeaders() },
    );

    return z.object({}).parse(response.data);
  }

  async getTransactions(
    filters: TransactionFilters = {
      type: "ALL",
      document: "BOTH",
    },
  ): Promise<Transaction[]> {
    await sleep();

    return fakeTransactionsBase.filter((tx) => {
      let match = true;

      match =
        tx.legalDate >=
          (filters.startDate ?? DateTime.local().minus({ years: 5 })) &&
        tx.legalDate <= (filters.endDate ?? new Date());

      if (filters.type === "INCOME") {
        match = tx.amount > 0;
      } else if (filters.type === "EXPENSES") {
        match = tx.amount < 0;
      }

      if (filters.document === "ABSENT") {
        match = match && tx.file === null;
      } else if (filters.document === "PRESENT") {
        match = match && tx.file !== null;
      }

      if (filters.query !== "" && filters.query !== undefined) {
        match =
          match &&
          tx.title
            .toLocaleLowerCase()
            .includes(filters.query.toLocaleLowerCase());
      }

      return match;
    });
  }

  async createTransaction(
    newTransaction: NewTransaction,
  ): Promise<Transaction> {
    const createdTransaction = { ...newTransaction, id: faker.datatype.uuid() };

    fakeTransactionsBase.push(createdTransaction);
    return createdTransaction;
  }

  async updateTransaction(
    updatedTransaction: UpdatedTransaction,
  ): Promise<Transaction> {
    let idx = 0;
    const transaction = fakeTransactionsBase.find((tx, index) => {
      idx = index;
      return tx.id === updatedTransaction.id;
    });
    if (transaction === undefined) {
      throw new Error("Transaction not found");
    }
    fakeTransactionsBase[idx] = {
      ...transaction,
      ...updatedTransaction,
      id: transaction.id,
    };

    return transaction;
  }

  async deleteTransaction(transactionId: string) {
    await sleep();
    const index = fakeTransactionsBase.findIndex(
      (tx) => tx.id === transactionId,
    );
    if (index === -1) {
      throw new Error("Transaction not found");
    }
    fakeTransactionsBase.splice(index, 1);
  }

  private async authHeaders() {
    const idToken =
      await getAuth(mickFirebaseProject).currentUser?.getIdToken(false);

    if (idToken === undefined) {
      throw new Error("Could not get token ID");
    }

    const impId = window.sessionStorage.getItem(IMP_ACCOUNT_ID_PARAM);

    return {
      Authorization: `Bearer ${idToken}`,
      ...(impId !== null ? { "X-Mick-Imp-Id": impId } : {}),
    };
  }

  private async authAdminHeaders(id: string) {
    const idToken =
      await getAuth(mickFirebaseProject).currentUser?.getIdToken(false);

    if (idToken === undefined) {
      throw new Error("Could not get token ID");
    }

    // const impId = window.sessionStorage.getItem(IMP_ACCOUNT_ID_PARAM);

    return {
      Authorization: `Bearer ${idToken}`,
      ...(id !== null ? { "X-Mick-Imp-Id": id } : {}),
    };
  }
}

export type TransactionType = "ALL" | "EXPENSES" | "INCOME";

export type DocumentStatus = "BOTH" | "PRESENT" | "ABSENT";
