import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Stack, Tooltip, Typography } from "@mui/material";

import { useAccount } from "../data/dataHooks";

export const RoleBadge = () => {
  const { data: account, isError, isLoading } = useAccount();

  if (isLoading || isError) {
    return null;
  }

  if (account === undefined) {
    return null;
  }

  const hasOtherRoleThanCustomer =
    account.roles.filter((r) => r !== "CUSTOMER").length > 0;

  if (!hasOtherRoleThanCustomer) {
    return null;
  }

  return (
    <Tooltip title="Vous êtes connecté avec ce(s) role(s)">
      <Stack
        sx={{
          borderRadius: 5,
          color: "primary.light",
          backgroundColor: "primary.dark",
          p: 0.5,
          mx: 1,
        }}
        direction="row"
        alignItems="center"
      >
        <AdminPanelSettingsIcon />
        <Stack mx={0.5} display={{ xs: "none", md: "block" }}>
          {account.roles.map((r) => (
            <Typography key={r} fontSize={10} fontWeight="bold">
              {r}
            </Typography>
          ))}
        </Stack>
      </Stack>
    </Tooltip>
  );
};
