import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { MickApiClient } from "../MickApiClient";
import { InitialProfile, Profile } from "../schemas";

import { NewTransaction, UpdatedTransaction } from "./transactions";
import { TransactionFilters } from "./types";

const ACCOUNT_KEY = "ACCOUNT";

export function useAccount(
  enabled = true,
  refetchInterval: number | false = false,
  retryOnMount = true,
) {
  return useQuery({
    queryKey: [ACCOUNT_KEY],
    queryFn: () => MickApiClient.sharedInstance.getAccount(),
    retry: 3,
    enabled,
    retryOnMount,
    refetchInterval,
  });
}

export function useAccountTwo() {
  return useQuery({
    // Useful to seed draft without accepting incoming refresh
    queryKey: [ACCOUNT_KEY, "INITIAL_ACCOUNT"],
    queryFn: () => MickApiClient.sharedInstance.getAccount(),
    retry: 3,
    refetchOnWindowFocus: false,
  });
}

export function useUpdateProfile() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [ACCOUNT_KEY, "update-profile"],
    mutationFn: async (
      params: Partial<Profile> & {
        event?: "immat_inpi_done" | "immat_cust_begin";
      },
    ) => MickApiClient.sharedInstance.updateProfile(params),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [ACCOUNT_KEY] });
    },
  });
}

export function useUpdateProfileAsAdmin(id: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [ACCOUNT_KEY, "update-profile"],
    mutationFn: async (
      params: Partial<Profile> & {
        event?: "immat_inpi_done" | "immat_cust_begin";
      },
    ) => MickApiClient.sharedInstance.updateProfileAsAdmin(id, params),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [ACCOUNT_KEY] });
    },
  });
}

export function useRegisterProduct() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [ACCOUNT_KEY, "register-product"],
    mutationFn: async ({
      promoCode,
      promoCodeName,
      promoAmount,
      remainingAmount,
      registrationOk,
    }: {
      promoCode: string | false;
      promoAmount: number;
      promoCodeName?: string;
      remainingAmount: number;
      registrationOk: boolean;
    }) =>
      MickApiClient.sharedInstance.registerProduct(
        registrationOk,
        promoCode,
        promoAmount,
        remainingAmount,
        promoCodeName,
      ),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [ACCOUNT_KEY] });
    },
  });
}

export function useCreateProfile() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [ACCOUNT_KEY, "cfate-profile"],
    mutationFn: async (params: InitialProfile) =>
      MickApiClient.sharedInstance.createProfile(params),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [ACCOUNT_KEY] });
    },
  });
}

export function useGetCheckoutUrl() {
  return useMutation({
    mutationKey: ["CHECKOUT", "PRODUCT_REGISTRATION"],
    mutationFn: async (promotionCodeId?: string) =>
      MickApiClient.sharedInstance.getCheckoutUrl(promotionCodeId),

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSuccess: async () => {},
  });
}

export function useCheckCouponCode() {
  return useMutation({
    mutationKey: ["CHECKOUT", "COUPON_CODE"],
    mutationFn: async (code: string) =>
      MickApiClient.sharedInstance.checkPromotionCode(code),
  });
}

const TRANSACTIONS_KEY = "TRANSACTIONS";

export function useTransactions(transactionsParams: TransactionFilters = {}) {
  return useQuery({
    queryKey: [TRANSACTIONS_KEY, transactionsParams],
    queryFn: () =>
      MickApiClient.sharedInstance.getTransactions(transactionsParams),

    retry: 3,
    placeholderData: keepPreviousData,
  });
}

export function useCreateTransaction() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [TRANSACTIONS_KEY],
    mutationFn: async (newTransaction: NewTransaction) => {
      console.log("creating transaction", newTransaction);
      await MickApiClient.sharedInstance.createTransaction(newTransaction);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [TRANSACTIONS_KEY] });
    },
  });
}

export function useUpdateTransaction() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [TRANSACTIONS_KEY, "update"],
    mutationFn: async (updatedTransaction: UpdatedTransaction) => {
      console.log("updating transaction", updatedTransaction);
      await MickApiClient.sharedInstance.updateTransaction(updatedTransaction);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [TRANSACTIONS_KEY] });
    },
  });
}

export function useDeleteTransaction() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [TRANSACTIONS_KEY, "delete"],
    mutationFn: async (transactionId: string) => {
      await MickApiClient.sharedInstance.deleteTransaction(transactionId);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [TRANSACTIONS_KEY] });
    },
  });
}

export const FILE_QUERY_KEY = "files";

export function useFiles() {
  return useQuery({
    queryKey: [FILE_QUERY_KEY, "all"],
    queryFn: () => MickApiClient.sharedInstance.getFiles(),
    retry: 3,
  });
}

export function useDeleteFile() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [TRANSACTIONS_KEY, "delete"],
    mutationFn: async (fileId: string) => {
      await MickApiClient.sharedInstance.deleteFile(fileId);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [TRANSACTIONS_KEY] });
    },
  });
}
