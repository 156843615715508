import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Tooltip,
  Link,
  Typography,
  Box,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import { NextLinkComposed } from "../navigation/NextLinkComposed";
import { WithPromis } from "../components/WithPromis";

interface Props {
  slightlyDisabled?: boolean;
}

export function PromisInsOffer() {
  return (
    <Card variant="outlined">
      <CardContent
        sx={{
          backgroundColor: grey[50],
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        <Typography variant="body1">
          Assurances & épargne pour avocats libéraux
        </Typography>
      </CardContent>
      <Divider />
      <CardContent>
        <img src="./analyze.jpg" height={228} />
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            flexWrap: "wrap",
            gap: "8px",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }} mt={2}>
            Je gère ma protection sociale
          </Typography>
          <WithPromis />
        </Box>
        <Typography variant="body1" mt={0.5}>
          Bénéficiez d'un accompagnement et de conseils personnalisés en
          prévoyance, mutuelle et épargne.
        </Typography>
        <Stack direction="row" spacing={1} mt={2}>
          <Button
            variant="outlined"
            to={"https://www.promis.fr/rdv-promis"}
            component={NextLinkComposed}
            target="_blank"
          >
            Programmer un rendez-vous gratuit
          </Button>
        </Stack>
        <Stack direction="row" spacing={1} mt={2}>
          <Typography variant="body2">
            Vous êtes déjà client chez Promis ?
          </Typography>
          <Link
            variant="body2"
            href="https://monespace.promis.fr"
            target="_blank"
            sx={{ textDecoration: "none", fontWeight: "500" }}
          >
            Accéder à votre espace personnel.
          </Link>
        </Stack>
      </CardContent>
    </Card>
  );
}
