import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Image from "next/image";
import { useCallback, useEffect, useState } from "react";

import { DISABLE_PAYMENT } from "../data/configData";
import {
  useAccount,
  useCheckCouponCode,
  useGetCheckoutUrl,
  useRegisterProduct,
} from "../data/dataHooks";
import { NextLinkComposed } from "../navigation/NextLinkComposed";
import { reportError } from "../reportError";
import { routeRegistrationProduct } from "../routes";
import { useDetectPartnerCode } from "../useDetectPartnerCode";

import { OfferProps } from "./OfferProps";

/** in cents */
const INITIAL_PRICE = 6900;

export function SetupOffer(props: OfferProps) {
  const { data: account } = useAccount();
  const { mutateAsync: getCheckoutUrl } = useGetCheckoutUrl();
  const { mutateAsync: registerProduct } = useRegisterProduct();

  const productEnabled = account?.productRegistrationEnabledAt != null;

  const [showCouponField, setShowCouponField] = useState(false);

  const { mutateAsync: checkCouponCode, isLoading: checkingCode } =
    useCheckCouponCode();

  const [couponCode, setCouponCode] = useState("");

  const [coupon, setCoupon] = useState<{
    promotionCodeId: string | null;
    amountOff: number | null;
  }>({
    promotionCodeId: null,
    amountOff: null,
  });

  const [noCodeFound, setNoCodeFound] = useState(false);

  const applyCoupon = useCallback(
    async (argCouponCode?: string) => {
      if (argCouponCode === undefined && couponCode === "") {
        return;
      }
      try {
        setNoCodeFound(false);
        const coupon = await checkCouponCode(argCouponCode ?? couponCode);
        setCoupon(coupon);
        setShowCouponField(false);
        if (coupon.promotionCodeId === null) {
          setNoCodeFound(true);
        }
      } catch (e) {
        alert("Impossible d'appliquer le coupon pour le moment");
        reportError(e);
      }
    },
    [checkCouponCode, couponCode],
  );

  const { partnerCode: foundPartnerCode, clearPartnerCode } =
    useDetectPartnerCode();

  useEffect(() => {
    if (
      account !== undefined &&
      foundPartnerCode !== null &&
      foundPartnerCode !== "" &&
      couponCode === ""
    ) {
      setCouponCode(foundPartnerCode);
      setShowCouponField(true);
      applyCoupon(foundPartnerCode).catch((e) => {
        console.error(e);
      });
    }
  }, [
    couponCode,
    account,
    setCouponCode,
    setShowCouponField,
    foundPartnerCode,
    applyCoupon,
  ]);

  const discountedPrice = INITIAL_PRICE - (coupon.amountOff ?? 0);
  return (
    <Card variant="outlined" sx={{ minWidth: { md: "50%" } }}>
      <CardContent
        sx={{
          backgroundColor: grey[50],
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        <Typography color={"text.secondary"}>
          Parcours d'installation pour avocats libéraux
        </Typography>
      </CardContent>
      <Divider />
      <CardContent>
        {props.hideImage !== true && (
          <Box mb={2}>
            <Image
              src="/checklist.jpg"
              height={228}
              width={300}
              alt="Illustration du pack d'installation"
              priority={true}
            />
          </Box>
        )}
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Je démarre mon activité
        </Typography>
        <Typography variant="body1" mt={0.5}>
          Simplifiez-vous les démarches indispensable liées à votre création
          d'entreprise, sans faire d'erreurs et sans rien oublier.
        </Typography>
        <Stack direction="row" spacing={1} mt={2}>
          {account === undefined && (
            <CircularProgress size={"1.5rem"} thickness={6} />
          )}
          {account !== undefined && productEnabled && (
            <Button
              variant="contained"
              component={NextLinkComposed}
              to={routeRegistrationProduct()}
            >
              Mon tableau de bord
            </Button>
          )}
          {account !== undefined && !productEnabled && (
            <Stack spacing={2}>
              <Stack
                spacing={1}
                sx={{ alignSelf: "flex-start", alignItems: "center" }}
              >
                <Button
                  variant="contained"
                  sx={{ py: 1.5 }}
                  onClick={async () => {
                    try {
                      const noPaiementNeeded =
                        discountedPrice <= 0 || DISABLE_PAYMENT;
                      await registerProduct({
                        promoCode: coupon.promotionCodeId ?? false,
                        promoCodeName: couponCode,
                        promoAmount: coupon.amountOff ?? 0,
                        remainingAmount: discountedPrice,
                        registrationOk: noPaiementNeeded,
                      });

                      if (!noPaiementNeeded) {
                        const response = await getCheckoutUrl(
                          coupon.promotionCodeId ?? undefined,
                        );
                        window.document.location.href = response.url;
                      }
                    } catch (e) {
                      reportError(e);
                      alert(
                        "Un problème est survenu, merci de réessayer ou contacter le support",
                      );
                    }
                  }}
                >
                  {discountedPrice > 0 ? "Acheter" : "Activer"} le pack •{" "}
                  <Typography
                    component={"span"}
                    sx={{
                      textDecoration:
                        coupon.amountOff !== null ? "line-through" : "none",
                      fontSize: "inherit",
                    }}
                    px={1}
                  >
                    {INITIAL_PRICE / 100}€ TTC
                  </Typography>
                  {"  "}
                  {coupon.amountOff !== null && (
                    <>{discountedPrice / 100}€ TTC</>
                  )}
                </Button>
                {!showCouponField && (
                  <Button
                    sx={{
                      color: "primary.main",
                      fontWeight: "600",
                      textTransform: "none",
                      alignSelf: "flex-start",
                    }}
                    onClick={() => setShowCouponField(!showCouponField)}
                  >
                    Utiliser un code de réduction
                  </Button>
                )}
              </Stack>
              {showCouponField && (
                <Stack spacing={1} direction={"row"}>
                  <TextField
                    autoFocus
                    size="small"
                    value={couponCode}
                    label={"Code réduction"}
                    onChange={(e) => {
                      setCouponCode(e.target.value);
                      setNoCodeFound(false);
                    }}
                    placeholder={"ex : MICK2024"}
                    InputProps={{ sx: { height: 45, pt: 0.5 } }}
                    InputLabelProps={{ sx: { pt: 0.5 } }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        applyCoupon().catch((e) => reportError(e));
                      }
                    }}
                  />
                  <LoadingButton
                    variant={"outlined"}
                    sx={{ py: 0.5, fontSize: "medium" }}
                    onClick={() => applyCoupon()}
                    disabled={checkingCode}
                    loading={checkingCode}
                  >
                    Utiliser ce code
                  </LoadingButton>
                  <Tooltip title={"Ne pas appliquer de code"}>
                    <IconButton
                      edge={"start"}
                      sx={{ alignSelf: "center" }}
                      onClick={() => {
                        clearPartnerCode();
                        setCoupon({ promotionCodeId: null, amountOff: null });
                        setCouponCode("");
                        setShowCouponField(false);
                        setNoCodeFound(false);
                      }}
                    >
                      <CancelIcon fontSize={"small"} color={"primary"} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
              {noCodeFound && (
                <Alert severity={"error"}>
                  Le code "{couponCode}" n'est pas valide
                </Alert>
              )}
              <Typography variant="caption" lineHeight={1.5}>
                Avant de confirmer votre achat, n’oubliez pas que le délai de
                traitement de votre demande d’immatriculation par nos experts
                Mick se fait dans un délai de <strong>48h ouvrables.</strong>
              </Typography>
              {discountedPrice > 0 && (
                <Typography
                  variant="caption"
                  color={"text.secondary"}
                  lineHeight={1.5}
                >
                  Vous serez redirigé pour le règlement chez notre prestataire
                  de paiement sécurisé (Stripe). Ce produit coûte{" "}
                  {(discountedPrice * 1.2) / 100}€ TTC, dont{" "}
                  {((discountedPrice * 0.2) / 100).toFixed(0)}€ de TVA que vous
                  pourrez déduire.
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
