import EmailIcon from "@mui/icons-material/Email";
import SupportIcon from "@mui/icons-material/Support";
import TodayIcon from "@mui/icons-material/Today";
import { List, ListItem, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { SUPPORT_EMAIL } from "../src/config";

const contactModalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, md: 700, lg: 900 },
  bgcolor: "background.paper",
  borderRadius: "0.6rem",
  boxShadow: 24,
  p: { xs: 2, md: 4 },
};

const meetingModalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "0.6rem",
  boxShadow: 24,
  p: 1,
  width: { xs: "90%", md: 700, lg: 900 },
  height: { xs: "90%", md: 670 },
};

const iframe = {
  // style={"meetings-iframe-container h-[620px] w-auto md:w-[350px] overflow-hidden"}
  height: "100%",
  width: "100%",
  overflow: "visible",
  border: "none",
};

function ChildModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button onClick={handleOpen}>
        <TodayIcon sx={{ marginRight: 1 }} />
        Réserver un créneau avec un conseiller Mick
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={meetingModalStyle}>
          <iframe
            style={iframe}
            title="Prise de rendez-vous avec un conseiller"
            src="https://meetings.hubspot.com/promis-odl/call-decouverte-mick?embed=true"
          ></iframe>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function ContactModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ display: "inline" }}>
      <Button onClick={handleOpen}>
        <SupportIcon />
        <Typography
          display={{ xs: "none", md: "inline-block" }}
          marginLeft={{ md: 0.5 }}
          fontWeight={600}
        >
          Aide
        </Typography>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={contactModalStyle}>
          <Typography variant="h4" component="h2" fontWeight={700}>
            Besoin d'aide ?
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Nos conseillers sont disponibles pour répondre à vos questions. Vous
            pouvez nous appeler, nous envoyer un email ou réserver un créneau de
            15 min avec l'un de nos experts.
          </Typography>
          <List>
            {/* <ListItem>
              <Button>
                <LocalPhoneIcon sx={{ marginRight: 1 }} />
                <Link href="tel:01XXXXXXXX">01 XX XX XX XX</Link>
              </Button>
            </ListItem> */}
            <ListItem>
              <Button>
                <EmailIcon sx={{ marginRight: 1 }} />
                <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
              </Button>
            </ListItem>
            <ListItem>
              <ChildModal />
            </ListItem>
          </List>
        </Box>
      </Modal>
    </div>
  );
}
