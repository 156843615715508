import { faker } from "@faker-js/faker";
import {
  amber,
  blue,
  deepOrange,
  green,
  pink,
  purple,
  teal,
} from "@mui/material/colors";
import { z } from "zod";

interface Category {
  name: string;
  backgroundColor: string;
  color: string;
  chartColor: string;
}

export const INCOME_CATEGORIES: Category[] = [
  {
    name: "Rémunérations reçues",
    color: green[50],
    backgroundColor: green[600],
    chartColor: green[300],
  },
  {
    name: "Autres",
    color: teal[50],
    backgroundColor: teal[600],
    chartColor: teal[300],
  },
];

export const EXPENSE_CATEGORIES: Category[] = [
  {
    name: "Rémunérations versées",
    color: deepOrange[50],
    backgroundColor: deepOrange[600],
    chartColor: deepOrange[300],
  },
  {
    name: "Frais de structure d'exercice",
    color: purple[50],
    backgroundColor: purple[600],
    chartColor: purple[300],
  },
  {
    name: "Frais matériels",
    color: blue[50],
    backgroundColor: blue[700],
    chartColor: blue[300],
  },
  {
    name: "Frais immatériels",
    color: pink[50],
    backgroundColor: pink[700],
    chartColor: pink[300],
  },
  {
    name: "Impôts, taxes et cotisations",
    color: amber[50],
    backgroundColor: amber[700],
    chartColor: amber[300],
  },
];

export interface Transaction {
  id: string;
  title: string;
  note: string | null;
  legalDate: Date;
  addedDate: Date;
  amount: number;
  vatAmount: number;
  vatRate: number;
  category: string | null;
  reference: string | null;
  file: string | null;
  deductible: boolean;
}

export type NewTransaction = Omit<Transaction, "id">;
export type UpdatedTransaction = Partial<Transaction> & { id: string };

export const transactionSchema = z.object({
  id: z.string(),
  title: z.string(),
  note: z.string().nullable(),
  legalDate: z.date(),
  addedDate: z.date(),
  amount: z.number(),
  vatAmount: z.number(),
  vatRate: z.number(),
  category: z.string().nullable(),
  reference: z.string().nullable(),
  file: z.string().nullable(),
  deductible: z.boolean(),
});

export function buildFakeTransaction(): Transaction {
  const fileName = `${faker.lorem.word(10)}-${faker.date
    .month()
    .toLowerCase()}.${faker.helpers.arrayElement([
    "pjg",
    "pdf",
    "png",
    "docx",
  ])}`;

  const referenceName =
    faker.commerce.department().slice(0, 3).toUpperCase() +
    "-" +
    faker.number.int(999);

  const isIncome: boolean = faker.helpers.arrayElement([
    false,
    false,
    false,
    false,
    true,
  ]);
  const absoluteAmount = Number(
    faker.commerce.price({ min: 0, max: 500, dec: 2, symbol: "" })
  );

  const vatRate = isIncome
    ? 0.2
    : faker.helpers.arrayElement([0.2, 0.2, 0.2, 0.1, 0.055, 0.021]);

  const vat = absoluteAmount * vatRate;
  const note = faker.lorem.words(5);
  const finalAmount = (isIncome ? 1 : -1) * absoluteAmount;

  return {
    id: faker.string.uuid(),
    title: faker.company.name() + " " + faker.commerce.productName(),
    note: faker.helpers.arrayElement([note, null]),
    // "Pour aller avec mon nouveau MacBook",
    legalDate: faker.date.recent({ days: 40 }),
    addedDate: faker.date.recent({ days: 1 }),
    amount: finalAmount,
    vatAmount: vat,
    vatRate,
    category: isIncome
      ? faker.helpers.arrayElement([
          INCOME_CATEGORIES[0].name,
          INCOME_CATEGORIES[0].name,
          ...INCOME_CATEGORIES.map((c) => c.name),
          null,
        ])
      : faker.helpers.arrayElement([
          ...EXPENSE_CATEGORIES.map((c) => c.name),
          null,
        ]),
    reference: faker.helpers.arrayElement([
      referenceName,
      referenceName,
      null,
      null,
      null,
    ]),
    file: faker.helpers.arrayElement([fileName, fileName, null]),
    deductible:
      isIncome || faker.helpers.arrayElement([true, true, true, false]),
  };
}
