export const TOS_URL =
  "https://www.mick-digit.com/mentions-legales-politique-de-confidentialite";

export const TIMEZONE = "Europe/Paris";

export const BASE_API = process.env.NEXT_PUBLIC_BASE_API;

export const WORK_TYPE_LAWYER = "Avocat libéral";
export const WORK_TYPE_OTHER = "Autre";

export const GDPR_NOTICE =
  "Conformément à la Loi Informatique et Libertés et au Règlement général sur la protection des données (RGPD), vous dipsosez d'un droit d'accès, de modification, de suppression et d'opposition au traitemnet de vos données à caractère personnel.";

export const DEFAULT_DATE_FORMAT = "dd/LL/yyyy";

export const IMP_ACCOUNT_ID_PARAM = "imp-account-id";

export const SUPPORT_EMAIL = "mick@promis.fr";

export const NEXT_PUBLIC_FIREBASE_API_KEY =
  process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
export const NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN =
  process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
export const NEXT_PUBLIC_FIREBASE_PROJECT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
export const NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET =
  process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET;
export const NEXT_PUBLIC_FIREBASE_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID;
export const NEXT_PUBLIC_FIREBASE_APP_ID =
  process.env.NEXT_PUBLIC_FIREBASE_APP_ID;
export const NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;
